<template>
  <div class="container">
    <canvas ref="myCanvas"></canvas>
    <div>
      <van-button
        size="large"
        style="width: 120px"
        round
        block
        type="primary"
        @click="onSubmit"
      >
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
import createjs from "createjs-npm";
export default {
  name: "index",
  data() {
    return {
      canvas: null,
      stage: null,
      title: null,
      color: null,
      oldPt: null,
      drawingCanvas: null,
      index: 0,
      colors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onSubmit() {
      let base64 = this.$refs.myCanvas.toDataURL("image/png");
      let imgFile = this.base64UrlToFile(base64, Math.random() * 10 + 1);
      const formData = new FormData();
      formData.append("file", imgFile, imgFile.name);
      this.$http
        .post("/dlr-wapp/api/upload", formData)
        .then(() => {
          this.$toast.success("提交成功！");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //将base64转换为文件
    base64UrlToFile: function (dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    init() {
      this.canvas = this.$refs.myCanvas;
      this.index = 0;
      // this.colors = ["#828b20", "#b0ac31", "#cbc53d", "#fad779", "#f9e4ad",
      //   "#faf2db", "#563512", "#9b4a0b", "#d36600", "#fe8a00", "#f9a71f"];
      this.colors = ["#f9a71f"];

      //新建舞台
      this.stage = new createjs.Stage(this.canvas);
      this.stage.autoClear = false;
      this.stage.enableDOMEvents(true);
      // 可以触摸
      createjs.Touch.enable(this.stage);
      createjs.Ticker.framerate = 24; //帧速率
      // 画笔
      this.drawingCanvas = new createjs.Shape();
      this.stage.addEventListener("stagemousedown", this.handleMouseDown);
      this.stage.addEventListener("stagemouseup", this.handleMouseUp);

      this.title = new createjs.Text(
        "Click and Drag to draw",
        "36px Arial",
        "#777777"
      );
      this.title.x = 300;
      this.title.y = 200;
      this.stage.addChild(this.title);

      this.stage.addChild(this.drawingCanvas);
      this.stage.update();
    },
    handleMouseDown(event) {
      if (!event.primary) {
        return;
      }
      if (this.stage.contains(this.title)) {
        this.stage.clear();
        this.stage.removeChild(this.title);
      }
      this.color = this.colors[this.index++ % this.colors.length];
      // this.stroke = Math.random() * 30 + 10 | 0;
      this.stroke = (Math.random() * 10 + 1) | 0;
      this.oldPt = new createjs.Point(this.stage.mouseX, this.stage.mouseY);
      this.oldMidPt = this.oldPt.clone();
      this.stage.addEventListener("stagemousemove", this.handleMouseMove);
    },
    handleMouseMove(event) {
      if (!event.primary) {
        return;
      }
      var midPt = new createjs.Point(
        (this.oldPt.x + this.stage.mouseX) >> 1,
        (this.oldPt.y + this.stage.mouseY) >> 1
      );
      this.drawingCanvas.graphics
        .clear()
        .setStrokeStyle(this.stroke, "round", "round")
        .beginStroke(this.color)
        .moveTo(midPt.x, midPt.y)
        .curveTo(this.oldPt.x, this.oldPt.y, this.oldMidPt.x, this.oldMidPt.y);
      this.oldPt.x = this.stage.mouseX;
      this.oldPt.y = this.stage.mouseY;
      this.oldMidPt.x = midPt.x;
      this.oldMidPt.y = midPt.y;
      this.stage.update();
    },
    handleMouseUp(event) {
      if (!event.primary) {
        return;
      }
      this.stage.removeEventListener("stagemousemove", this.handleMouseMove);
    },
  },
};
</script>

<style scoped>
.container {
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  /*background-color: #2a7efb;*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container canvas {
  background-color: #f8efe6;
  width: 100%;
  height: 50%;
}
</style>
